<template>
<v-layout justify-center class="dashboard-container">
    <v-flex xs12 sm12 md6>
        <material-card title="Product List/Lista de Productos" icon="mdi-basket">
            <v-card-text class="page-info dashboard-info">
                <v-btn color="primary" dark class="mb-2" to="product/new">New/Nuevo</v-btn>
                <v-data-table :headers="headers" :items="list" class="elevation-1" :loading="isLoading">
                    <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
                    <template slot="items" slot-scope="props">
                        <td>{{ props.item.name }}</td>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }" class="justify-center layout px-0">
                        <v-icon small class="mr-2" @click="edit(item)" title="Edit/Editar">
                            mdi-pencil
                        </v-icon>
                    </template>
                </v-data-table>
            </v-card-text>
        </material-card>
    </v-flex>
</v-layout>
</template>

<script>
import {
    mapGetters,
    mapActions
} from "vuex";
import products from "../../services/api/product";

export default {
    name: "productList",
    data() {
        return {
            headers: [{
                    text: "Name/Nombre",
                    value: "name"
                },
                {
                    text: "Actions/Acciones",
                    value: "actions",
                    sorteable: false,
                }
            ],
            list: []
        };
    },
    components: {
        MaterialCard: () => import('@/components/base/MaterialCard'),
    },
    async created() {
        this.getData();
    },
    methods: {
        ...mapActions(["setLoading"]),
        async getData() {
            this.setLoading(true);
            try {
                const response = await products.getAll();
                this.list = response.body;
            } catch (e) {
                this.$toast.error(e.body.message);
            }
            this.setLoading(false);
        },
        edit(product) {
            this.$router.push({
                name: "productEdit",
                params: {
                    id: product.id
                }
            });
        }
    },
    computed: {
        ...mapGetters(["isLoading"])
    }
};
</script>

<style>
.v-datatable .column:nth-child(2) {
    text-align: center !important;
}
</style>
